<template>
    <div>
        <iframe :src="'/application/pdf/' + lang + '/Price_list_PKI.pdf'"></iframe>
    </div>
</template>
<script>
    export default {
      computed: {
        lang () {
          return this.getLang ? this.getLang : this.$i18n.locale
        },
      },
    }
</script>
<style lang="sass">
    iframe
        width: 100%
        min-height: 50vw
        height: calc(100vh - 220px)
        border: 0
</style>
